import * as React from 'react';

import BasicLayout from '../layouts/BasicLayout';
import { TitleWrapper } from '../components/listPages/reusableComponents';
import LogoWithSubtitle from '../components/LogoWithSubtitle';
import { graphql } from 'gatsby';
import Slack from '../components/Slack/SlackWrapper';
import PageMeta, { IPageMeta } from '../components/PageMeta';
import { textToParagraphs } from '../utils/strings';

export interface ISlackPageProps {
  data: {
    slackPage: {
      meta: IPageMeta;
      title: string;
      subtitle: string;
      ctaTitle?: string;
      ctaSubtitle?: string;
    };
  };
}

export const SlackPage: React.SFC<ISlackPageProps> = props => {
  const { meta, title, subtitle, ctaTitle, ctaSubtitle } = props.data.slackPage;
  return (
    <BasicLayout>
      <PageMeta {...meta} title="Slack" />
      <TitleWrapper>
        <LogoWithSubtitle subtitle="Slack" />
        <h1>{title}</h1>
        {textToParagraphs(subtitle)}
      </TitleWrapper>
      <Slack includeCTA={true} />
    </BasicLayout>
  );
};

SlackPage.displayName = 'SlackPage';

export default SlackPage;

export const slackPageQuery = graphql`
  query slackPageQuery {
    slackPage: sanitySlack(_id: { eq: "slackId" }) {
      meta {
        seoDescription
        seoTitle
        ogImage {
          asset {
            fixed(width: 1200) {
              src
            }
          }
        }
      }
      title
      subtitle
      ctaTitle
      ctaSubtitle
    }
  }
`;

// @TODO
/*
- uncomment `fields` when that's solved
*/
